
export const NAVIGATE_ROOT = "/"
export const NAVIGATE_HOME = "/accueil"
export const NAVIGATE_WORKSHOP_PAGE = "/atelier-cuisine"
export const NAVIGATE_LOGIN_PAGE = "/login"
export const NAVIGATE_SIGNUP_PAGE = "/inscription"
export const NAVIGATE_PAYMENT_PAGE = "/paiement"
export const NAVIGATE_PAYMENT_SUCCESS_PAGE = "/paiement-ok"
export const NAVIGATE_INVIATION_PAGE = "/invitation"
export const NAVIGATE_MY_RESERVATIONS_PAGE = "/mes-reservations"
export const NAVIGATE_TABLE_RESERVATION_PAGE = "/reserver-votre-table"

export const NAVIGATE_ADMIN_PAGE = "/admin"
export const NAVIGATE_ADMIN_SLOTS_PAGE = "/admin/slots"
export const NAVIGATE_ADMIN_USERS_PAGE = "/admin/utilisateurs"
export const NAVIGATE_PASSWORD_RESET = "/reset-password/:passwordResetToken"

export const NAVIGATE_AGENCY_PAGE = "/agence"
export const NAVIGATE_AGENCY_AGENTS_PAGE = "/admin/agents"


export const isActivePath = (known_path: string, current_path: string, strict: boolean = false) => {
    var res = false
    if (strict) {
        if (current_path === known_path) {
            res = true
        }
    } else {
        if (current_path.startsWith(known_path)) {
            res = true
        }
    }
    return res
}



