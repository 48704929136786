import { FC } from 'react'
import { Flexbox } from '../components/utils/Flexbox'
import { Layout } from "./Layout"
import { Footer } from '../sections/Footer'
import { GuestonlineSection } from '../sections/tableReservations/GuestonlineSection'

interface IProps {
}

export const TableReservationPage: FC<IProps> = (props) => {


  return (
    <div className="TableReservationPage">
      <Layout>
        <Flexbox direction='column'>
          <GuestonlineSection />
        </Flexbox>
      </Layout>
    </div >
  )
}
