import { FC, useEffect } from 'react';
import { Flexbox } from '../../components/utils/Flexbox';

interface IProps { }


// Étendre l'interface Window pour inclure iFrameResize
declare global {
    interface Window {
        iFrameResize?: () => void;
    }
}

export const GuestonlineSection: FC<IProps> = (props) => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.covermanager.com/js/iframeResizer.min.js";
        script.type = "text/javascript";
        script.onload = () => {
            if (window.iFrameResize) {
                window.iFrameResize();
            }
        };
        document.body.appendChild(script);
    }, []);

    return (
        <>
            <Flexbox direction='column' sx={{
                width: "100%",
                py: 4,
            }}>
                <iframe
                    id="restaurant-mazeniene" title="Reservas"
                    src="https://www.covermanager.com/reserve/module_restaurant/restaurant-mazeniene/french"
                    allow="payment" style={{ border: "none" }} height="550" width="100%" onLoad={() => window.iFrameResize && window.iFrameResize()}></iframe>
            </Flexbox>
        </>
    )
}


