import { FC } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Home } from './pages/Home';
import { NAVIGATE_ADMIN_PAGE, NAVIGATE_ADMIN_SLOTS_PAGE, NAVIGATE_ADMIN_USERS_PAGE, NAVIGATE_AGENCY_AGENTS_PAGE, NAVIGATE_AGENCY_PAGE, NAVIGATE_INVIATION_PAGE, NAVIGATE_LOGIN_PAGE, NAVIGATE_MY_RESERVATIONS_PAGE, NAVIGATE_PASSWORD_RESET, NAVIGATE_PAYMENT_PAGE, NAVIGATE_PAYMENT_SUCCESS_PAGE, NAVIGATE_ROOT, NAVIGATE_SIGNUP_PAGE, NAVIGATE_WORKSHOP_PAGE, NAVIGATE_HOME, NAVIGATE_TABLE_RESERVATION_PAGE } from './utils/Navigation';
import { WorkshopPage } from './pages/WorkshopPage';
// import { Page404 } from './pages/Page404';

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { MAZENIENE_WEB_JWT_TOKEN } from "./redux/features/global/apolloSlice";
import { LoginPage } from './pages/LoginPage';
import { SignupPage } from './pages/SignupPage';
import { AdminPage } from './pages/admin/AdminPage';
import { AdminSlotsPage } from './pages/admin/AdminSlotsPage';

// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers";

import { setContext } from '@apollo/client/link/context';
import { PaymentPage } from './pages/PaymentPage';

import { onError } from '@apollo/client/link/error';
import { addMessage } from './components/messages/Message';
import { EnumMessageType } from './enums';
import { PaymentSuccessPage } from './pages/PaymentSuccessPage';
import { AdminUsersPage } from './pages/admin/AdminUsersPage';
import { InvitationPage } from './pages/InvitationPage';
import { MyReservationsPage } from './pages/MyReservationsPage';
import { PasswordResetPage } from './pages/PasswordResetPage';
import { AgencyPage } from './pages/agency/AgencyPage';
import { AgencyAgentsPage } from './pages/agency/AgencyAgentsPage';
import { BuildingPage } from './pages/BuildingPage';
import { TableReservationPage } from './pages/TableReservationPage';


declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    quaternary: Palette['primary'];
    custom1: Palette['primary'];
    custom2: Palette['primary'];
    custom3: Palette['primary'];
    gris1: Palette['primary'];
    gris2: Palette['primary'];
    gris3: Palette['primary'];
    gris4: Palette['primary'];
    cancel: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    quaternary: PaletteOptions['primary'];
    custom1: PaletteOptions['primary'];
    custom2: PaletteOptions['primary'];
    custom3: PaletteOptions['primary'];
    gris1: PaletteOptions['primary'];
    gris2: PaletteOptions['primary'];
    gris3: PaletteOptions['primary'];
    gris4: PaletteOptions['primary'];
    cancel: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    primary: React.CSSProperties;
    secondary: React.CSSProperties;
    tertiary: React.CSSProperties;
    quaternary: React.CSSProperties;
    custom1: React.CSSProperties;
    custom2: React.CSSProperties;
    custom3: React.CSSProperties;
    gris1: React.CSSProperties;
    gris2: React.CSSProperties;
    gris3: React.CSSProperties;
    gris4: React.CSSProperties;
    cancel: React.CSSProperties;
    writer: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    primary?: React.CSSProperties;
    secondary?: React.CSSProperties;
    tertiary?: React.CSSProperties;
    quaternary?: React.CSSProperties;
    custom1?: React.CSSProperties;
    custom2?: React.CSSProperties;
    custom3?: React.CSSProperties;
    gris1?: React.CSSProperties;
    gris2?: React.CSSProperties;
    gris3?: React.CSSProperties;
    gris4?: React.CSSProperties;
    cancel?: React.CSSProperties;
    writer?: React.CSSProperties;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    custom1: true;
    custom2: true;
    custom3: true;
    gris1: true;
    gris2: true;
    gris3: true;
    gris4: true;
    cancel: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    custom1: true;
    custom2: true;
    custom3: true;
    gris1: true;
    gris2: true;
    gris3: true;
    gris4: true;
    cancel: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    custom1: true;
    custom2: true;
    custom3: true;
    gris1: true;
    gris2: true;
    gris3: true;
    gris4: true;
    cancel: true;
    information: true;
  }
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    custom1: true;
    custom2: true;
    custom3: true;
    gris1: true;
    gris2: true;
    gris3: true;
    gris4: true;
    cancel: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    quaternary: true;
    custom1: true;
    custom2: true;
    custom3: true;
    gris1: true;
    gris2: true;
    gris3: true;
    gris4: true;
    cancel: true;
    writer: true;
  }
}


const theme = createTheme({
  palette: {
    primary: {
      main: "#4b2204", //chocolate
    },
    secondary: {
      // main: "#FFDD00", //yellow
      // dark: "#f59c00", //Orange
      main: "#ed7d31", //orange
    },
    tertiary: {
      main: "#FFFFFF", //white
    },
    quaternary: {
      main: "#0077BE", //blue
    },
    cancel: {
      main: "#888888",
      contrastText: '#F5F3F5',
    },
    background: {
      default: "#F5F3F5",
    },
    custom1: {
      main: "#E7E6E6",
    },
    custom2: {
      main: "#F2F2F2",
    },
    custom3: {
      main: "#4b2204", //chocolate
    },
    gris1: {
      main: "#C0C0C0", //ciel + arrière-plan.
    },
    gris2: {
      main: "#939393", //ombre de la case, de la végétation sur le mur et les triangles du portillon + dessin au trait de la végétation.
    },
    gris3: {
      main: "#CACACA", //couleur de la végétation.
    },
    gris4: {
      main: "#676767", //Récup par moi sur le subtitle du logo (Restaurant & Bar)
    },

  },
  typography: {
    primary: {
      fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    },
    secondary: {
      // fontFamily from caro's draft : Bahnschrift condensed ; Maiandra GD ; Arial ; Calibri ; Helvetica.
      fontFamily: "Barlow",
    },
    tertiary: {
      fontFamily: ['"Amatic SC"', 'sans-serif'].join(','), // Title du logo (Le Mazéniène)
    },
    quaternary: {
      fontFamily: ['"EB Garamond"', 'serif'].join(','), //subtitle du logo (Restaurant & Bar)
    },
    writer: {
      // fontFamily: "Caveat",
      fontFamily: 'Playpen Sans',
    },
  },
});

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors) {
//     graphQLErrors.map(({ message, locations, path }) => {
//       console.log(`Graphql ERROR : ${message}`)
//       return false
//     })
//   }
// })


// let link = from([
//   errorLink,
//   new HttpLink({
//     uri: process.env.REACT_APP_MAZENIENE_CORE_URI,
//     headers: {
//       FrontApp: "mazeniene_web",
//       FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
//     }
//   })
// ])

// const mazenieneJwtToken = localStorage.getItem(MAZENIENE_WEB_JWT_TOKEN)
// if (mazenieneJwtToken) {
//   link = from([
//     errorLink,
//     new HttpLink({
//       uri: process.env.REACT_APP_MAZENIENE_CORE_URI,
//       headers: {
//         FrontApp: "mazeniene_web",
//         FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
//         authToken: mazenieneJwtToken,
//       }
//     })
//   ])
// }

// export const apolloClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: link,
// })

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_MAZENIENE_CORE_URI,
});

const authLink = setContext((_, { headers }) => {
  const mazenieneJwtToken = localStorage.getItem(MAZENIENE_WEB_JWT_TOKEN)
  return {
    headers: {
      ...headers,
      FrontApp: "mazeniene_web",
      FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
      authToken: mazenieneJwtToken ? mazenieneJwtToken : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(`Erreur GraphQL : ${message}`);
      addMessage({
        location: "App",
        type: EnumMessageType.Error,
        message: message,
      })
    });
  } else if (networkError) {
    // Je fais exprès de faire un else if pour pas qu'il y ait trop de messages d'un coup..
    console.error(`Erreur réseau GraphQL : ${networkError}`);
    addMessage({
      location: "App",
      type: EnumMessageType.Error,
      message: networkError.message,
    })
  }
});


export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});

interface IProps { }

export const App: FC<IProps> = (props) => {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="App">
            <BrowserRouter>
              <Routes>
                <Route path={`${NAVIGATE_ROOT}`} element={<BuildingPage />} />
                <Route path={`${NAVIGATE_HOME}`} element={<Home />} />
                <Route path={`${NAVIGATE_WORKSHOP_PAGE}`} element={<WorkshopPage />} />
                <Route path={`${NAVIGATE_LOGIN_PAGE}`} element={<LoginPage />} />
                <Route path={`${NAVIGATE_SIGNUP_PAGE}`} element={<SignupPage />} />
                <Route path={`${NAVIGATE_PAYMENT_PAGE}`} element={<PaymentPage />} />
                <Route path={`${NAVIGATE_PAYMENT_SUCCESS_PAGE}`} element={<PaymentSuccessPage />} />
                <Route path={`${NAVIGATE_INVIATION_PAGE}`} element={<InvitationPage />} />
                <Route path={`${NAVIGATE_MY_RESERVATIONS_PAGE}`} element={<MyReservationsPage />} />
                <Route path={`${NAVIGATE_PASSWORD_RESET}`} element={<PasswordResetPage />} />
                <Route path={`${NAVIGATE_TABLE_RESERVATION_PAGE}`} element={<TableReservationPage />} />

                <Route path={`${NAVIGATE_ADMIN_PAGE}`} element={<AdminPage />} />
                <Route path={`${NAVIGATE_ADMIN_SLOTS_PAGE}`} element={<AdminSlotsPage />} />
                <Route path={`${NAVIGATE_ADMIN_USERS_PAGE}`} element={<AdminUsersPage />} />

                <Route path={`${NAVIGATE_AGENCY_PAGE}`} element={<AgencyPage />} />
                <Route path={`${NAVIGATE_AGENCY_AGENTS_PAGE}`} element={<AgencyAgentsPage />} />

                {/* <Route path="/*" element={<Page404 />} /> */}
              </Routes>
            </BrowserRouter>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}


export default App
